@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Poppins', sans-serif;
}

/* region Animations */
.hover-animation:hover {
  transition: all 0.2s ease-in-out;
  transform: scale(1.05);
}

.hover-animation-no-scale:hover {
  transition: all 0.2s ease-in-out;
}

.hover-animation,
.hover-animation-no-scale {
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

/* endregion */

/* region Inputs */
.btn-primary {
  @apply text-white bg-neutral-3 font-semibold px-4 py-1 rounded-full font-bold cursor-pointer;
}

.btn-primary-outlined {
  @apply border-neutral-6 border-2 hover:border-neutral-5 text-neutral-2 px-6 py-3 rounded-2xl font-bold cursor-pointer;
}
/* endregion */